import React, { useMemo } from "react";

// material-ui
import { alpha, useTheme } from "@mui/material/styles";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";

// third-party
import {
  useFilters,
  useRowSelect,
  useTable,
  usePagination,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import SyntaxHighlight from "utils/SyntaxHighlight";
import { renderFilterTypes } from "utils/react-table";
import { Button } from "@mui/material";
import {
  TablePagination,
  IndeterminateCheckbox,
} from "components/third-party/ReactTable";

// Sample Data
const makeData = () => [
  {
    gameName: "Titan Quest",
    country: "United States",
    countryCode: "US",
    psp: "Stripe",
  },
  {
    gameName: "Diablo IV",
    country: "Canada",
    countryCode: "CA",
    psp: "PayPal",
  },
  {
    gameName: "World of Warcraft",
    country: "United Kingdom",
    countryCode: "GB",
    psp: "Adyen",
  },
  {
    gameName: "Final Fantasy XIV",
    country: "Japan",
    countryCode: "JP",
    psp: "Square",
  },
  {
    gameName: "Lost Ark",
    country: "Germany",
    countryCode: "DE",
    psp: "Klarna",
  },
  {
    gameName: "Black Desert Online",
    country: "South Korea",
    countryCode: "KR",
    psp: "NaverPay",
  },
  {
    gameName: "Path of Exile",
    country: "Australia",
    countryCode: "AU",
    psp: "Stripe",
  },
  {
    gameName: "Guild Wars 2",
    country: "France",
    countryCode: "FR",
    psp: "Worldpay",
  },
];

// Filter Input Component
const DefaultColumnFilter = ({
  column: { filterValue, setFilter, Header },
}: any) => (
  <TextField
    value={filterValue || ""}
    onChange={(e) => setFilter(e.target.value || undefined)} // Set undefined to remove the filter
    placeholder={`Search ${Header}`}
    variant="outlined"
    size="small"
    fullWidth
  />
);

// Add this new component after DefaultColumnFilter
const SelectColumnFilter = ({
  column: { filterValue = [], setFilter, preFilteredRows, id, Header },
}: any) => {
  const options = useMemo(() => {
    const uniqueOptions = new Set<string>();
    preFilteredRows.forEach((row: any) => {
      uniqueOptions.add(row.values[id]);
    });
    return preFilteredRows
      .reduce((acc: any[], row: any) => {
        if (!acc.find((item) => item.country === row.values[id])) {
          acc.push({
            country: row.values[id],
            countryCode: row.original.countryCode,
          });
        }
        return acc;
      }, [])
      .sort((a: any, b: any) => a.country.localeCompare(b.country));
  }, [id, preFilteredRows]);

  const handleChange = (event: any) => {
    const value = event.target.value;
    // If "Select All" is selected
    if (value.includes("all")) {
      // If all options were already selected (including 'all'), clear the selection
      if (filterValue.length === options.length + 1) {
        setFilter([]);
      } else {
        // Otherwise, select all options
        setFilter(["all", ...options.map((opt: any) => opt.country)]);
      }
    } else {
      setFilter(value);
    }
  };

  return (
    <Select
      multiple
      value={filterValue || []}
      onChange={handleChange}
      size="small"
      fullWidth
      displayEmpty
      renderValue={(selected: any) => {
        const selectedArray = Array.isArray(selected) ? selected : [];
        if (selectedArray.length === 0) {
          return "All countries";
        }
        if (
          selectedArray.includes("all") ||
          selectedArray.length === options.length
        ) {
          return "All countries";
        }
        if (selectedArray.length > 1) {
          return `${selectedArray.length} countries selected`;
        }
        return (
          options.find((opt: any) => opt.country === selectedArray[0])
            ?.country || "All countries"
        );
      }}
    >
      <MenuItem value="all">
        <Stack direction="row" spacing={1} alignItems="center">
          <Checkbox
            checked={filterValue?.includes("all")}
            indeterminate={
              filterValue?.length > 0 &&
              filterValue?.length < options.length + 1
            }
          />
          <Typography>Select All</Typography>
        </Stack>
      </MenuItem>
      {options.map((option: any) => (
        <MenuItem key={option.country} value={option.country}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Checkbox checked={filterValue?.includes(option.country)} />
            <Typography>{option.country}</Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
  );
};

function ReactTable({ columns, data }: { columns: Column[]; data: [] }) {
  const theme = useTheme();
  const filterTypes = useMemo(() => renderFilterTypes, []);

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter, // Default filter for all columns
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    state: { selectedRowIds, pageIndex, pageSize },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      defaultColumn, // Include default column settings
      initialState: {
        pageIndex: 0,
        selectedRowIds: {},
      },
    },
    useFilters, // Add the useFilters hook
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.allColumns.push((columns: Column[]) => [
        {
          id: "row-selection-chk",
          accessor: "Selection",
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <IndeterminateCheckbox
              indeterminate
              {...getToggleAllPageRowsSelectedProps()}
            />
          ),
          Cell: ({ row }: any) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  return (
    <>
      <MainCard
        title=" "
        content={false}
        secondary={
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              disabled={Object.keys(selectedRowIds).length === 0}
              sx={{
                height: "48px",
              }}
            >
              Update Payment Service Provider
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                height: "48px",
              }}
            >
              Add Rule
            </Button>
          </Stack>
        }
      >
        <ScrollX>
          <Stack spacing={3}>
            <Table {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow
                    {...headerGroup.getHeaderGroupProps()}
                    sx={{ "& > th:first-of-type": { width: "58px" } }}
                  >
                    {headerGroup.headers.map((column: HeaderGroup) => (
                      <TableCell
                        {...column.getHeaderProps([
                          { className: column.className },
                        ])}
                      >
                        {column.render("Header")}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: HeaderGroup) => (
                      <TableCell
                        {...column.getHeaderProps([
                          { className: column.className },
                        ])}
                      >
                        {column.canFilter && column.id !== "row-selection-chk"
                          ? column.render("Filter")
                          : null}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {page.map((row: Row, i: number) => {
                  prepareRow(row);
                  return (
                    <TableRow
                      {...row.getRowProps()}
                      onClick={() => {
                        row.toggleRowSelected();
                      }}
                      sx={{
                        cursor: "pointer",
                        bgcolor: row.isSelected
                          ? alpha(theme.palette.primary.lighter, 0.35)
                          : "inherit",
                      }}
                    >
                      {row.cells.map((cell: Cell) => (
                        <TableCell
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell sx={{ p: 2, pb: 2 }} colSpan={8}>
                    <TablePagination
                      gotoPage={gotoPage}
                      rows={rows}
                      setPageSize={setPageSize}
                      pageSize={pageSize}
                      pageIndex={pageIndex}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {/* <SyntaxHighlight>
              {JSON.stringify(
                {
                  selectedRowIndices: selectedRowIds,
                  "selectedFlatRows[].original": selectedFlatRows.map(
                    (d: Row) => d.original
                  ),
                },
                null,
                2
              )}
            </SyntaxHighlight> */}
          </Stack>
        </ScrollX>
      </MainCard>
    </>
  );
}

const RoutingRulesTable = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Game Name",
        accessor: "gameName",
        Cell: ({ value }: { value: string }) => (
          <Typography>{value}</Typography>
        ),
      },
      {
        Header: "Country",
        accessor: "country",
        Filter: SelectColumnFilter,
        filter: (rows: Row[], columnIds: string[], filterValue: string[]) => {
          if (!filterValue?.length) return rows;
          return rows.filter((row) => {
            const rowValue = row.values[columnIds[0]];
            return filterValue.includes(rowValue);
          });
        },
        Cell: ({ value, row }: { value: string; row: any }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <img
              src={`https://flagcdn.com/w20/${row?.original?.countryCode.toLowerCase()}.png`}
              alt={`${value} flag`}
              style={{ width: 20, height: "auto" }}
            />
            <Typography>{value}</Typography>
          </Stack>
        ),
      },
      {
        Header: "Payment Service Provider (Sub-MID)",
        accessor: "psp",
      },
    ],
    []
  );

  const data = useMemo(() => makeData(), []);

  return <ReactTable columns={columns} data={data as any} />;
};

export default RoutingRulesTable;
