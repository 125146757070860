import { useMutation } from "@tanstack/react-query";
import axiosServices from "utils/axios";

interface RestoreReceiptsParams {
  email: string;
  orderIds: string[];
}

const restoreReceiptsMutation = async ({
  email,
  orderIds,
}: RestoreReceiptsParams) => {
  return await axiosServices.post<{
    email: string;
    orderIds: string[];
  }>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/restore-receipts`,
    { email, orderIds }
  );
};

export default function useReceipts() {
  const restoreReceipts = useMutation({
    mutationFn: (params: RestoreReceiptsParams) =>
      restoreReceiptsMutation(params),
  });

  return { restoreReceipts };
}
