import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import useReceipts from "hooks/useReceipts";
import { useSnackbar } from "notistack";

interface RestoreReceiptsDrawerProps {
  restoreReceiptsDrawerOpen: boolean;
  setRestoreReceiptsDrawerOpen: (open: boolean) => void;
}

const RestoreReceiptsDrawer = ({
  restoreReceiptsDrawerOpen,
  setRestoreReceiptsDrawerOpen,
}: RestoreReceiptsDrawerProps) => {
  const { restoreReceipts } = useReceipts();
  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      orderIds: [],
      email: localStorage.getItem("__ac_user") || "",
    },
    onSubmit: (values) => {
      restoreReceipts.mutate(
        {
          email: values.email,
          orderIds: values.orderIds,
        },
        {
          onSuccess: () => {
            setRestoreReceiptsDrawerOpen(false);
            enqueueSnackbar("Receipts restored successfully", {
              variant: "success",
            });
          },
          onError: (err) => {
            enqueueSnackbar(`Error restoring receipts: ${err.message}`, {
              variant: "error",
            });
          },
        }
      );
    },
  });

  return (
    <Drawer
      anchor="right"
      open={restoreReceiptsDrawerOpen}
      onClose={() => setRestoreReceiptsDrawerOpen(false)}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "100%", sm: "75%", md: "350px" },
          padding: 3,
        },
      }}
    >
      <Typography variant="h4" whiteSpace="normal" gutterBottom sx={{ mb: 2 }}>
        Restore Receipts
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Stack py={1} spacing={4}>
          <Stack spacing={1} mb={-2}>
            <InputLabel
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              Email Address
              <Typography
                variant="subtitle2"
                sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
              >
                The email address to send the receipts to
              </Typography>
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                {...formik.getFieldProps("email")}
                disabled
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <Box py={1} />
            <InputLabel
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              Order IDs
              <Typography
                variant="subtitle2"
                width="100%"
                sx={{ wordBreak: "break-word", overflowWrap: "break-word" }}
              >
                For multiple receipts, separate receipt Ids with a line break
              </Typography>
            </InputLabel>
            <FormControl fullWidth>
              <TextField
                multiline
                rows={4}
                {...formik.getFieldProps("orderIds")}
                error={
                  formik.touched.orderIds && Boolean(formik.errors.orderIds)
                }
                helperText={formik.touched.orderIds && formik.errors.orderIds}
              />
              <Typography
                variant="subtitle2"
                color="text.secondary"
                py={1}
                pl={1}
              >
                For example:
                <br />
                <code>
                  673ca3e0cf40649516080950
                  <br />
                  8f4d92b1ae5738c247159361
                  <br />
                  2c9e15f7d83a904625473182
                  <br />
                  5b1a46c9ef2085d739264093
                </code>
              </Typography>
            </FormControl>
          </Stack>
          <Stack spacing={1}>
            <Button variant="contained" color="primary" type="submit">
              Restore
            </Button>
          </Stack>
        </Stack>
      </form>
    </Drawer>
  );
};

export default RestoreReceiptsDrawer;
