import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import useAdminUsers from "hooks/useAdminUsers";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { formatSectionName } from "../../util";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import { usePermissions } from "utils/permissions/usePermissions";

interface AddRoleDrawerProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
  incomingPermissions?: string[];
  incomingRoleName?: string;
  incomingRoleId?: string;
}

const AddRoleDrawer = ({
  open,
  onClose,
  refetch,
  incomingPermissions = [],
  incomingRoleName = "",
  incomingRoleId = "",
}: AddRoleDrawerProps) => {
  const { hasPermission } = usePermissions();
  const [permissions, setPermissions] = useState<string[]>(incomingPermissions);
  const [roleName, setRoleName] = useState(incomingRoleName);
  const [roleId, setRoleId] = useState<string>(incomingRoleId);

  useEffect(() => {
    if (!!incomingRoleName) {
      setPermissions(incomingPermissions);
      setRoleName(incomingRoleName);
      setRoleId(incomingRoleId);
    }
  }, [incomingPermissions, incomingRoleName, incomingRoleId]);

  const { addAdminRoleMutation, updateAdminRoleMutation } = useAdminUsers();

  const handleAddRole = (roleName: string, permissions: string[]) => {
    if (!!incomingRoleName) {
      updateAdminRoleMutation.mutate(
        { roleId, permissions: permissions.slice(1) },
        {
          onSuccess: () => {
            enqueueSnackbar("Role updated successfully", {
              variant: "success",
            });
            onClose();
            setPermissions([]);
            setRoleName("");
            refetch();
          },
          onError: () => {
            enqueueSnackbar("Failed to update role", {
              variant: "error",
            });
          },
        }
      );
      return;
    }
    addAdminRoleMutation.mutate(
      { presentationName: roleName, permissions },
      {
        onSuccess: () => {
          enqueueSnackbar("Role added successfully", {
            variant: "success",
          });
          refetch();
          onClose();
          setPermissions([]);
          setRoleName("");
        },
        onError: () => {
          enqueueSnackbar("Failed to add role", {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setPermissions([]);
        setRoleName("");
        onClose();
      }}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "100%", sm: "75%", md: "650px" },
          padding: 3,
        },
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
        Add a new Role
      </Typography>
      <Stack>
        <Stack py={1} spacing={4}>
          <Stack spacing={1}>
            <InputLabel>Role Name</InputLabel>
            <FormControl fullWidth>
              {!!incomingRoleName ? (
                <Tooltip
                  title="Role name cannot be modified"
                  placement="top"
                  arrow
                >
                  <TextField
                    type="text"
                    value={roleName}
                    helperText="For example: Business Operations"
                    disabled={!!incomingRoleName}
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                </Tooltip>
              ) : (
                <TextField
                  type="text"
                  value={roleName}
                  helperText="For example: Business Operations"
                  disabled={!!incomingRoleName}
                  onChange={(e) => setRoleName(e.target.value)}
                />
              )}
            </FormControl>
            <Box mt={2}></Box>
            <InputLabel>Permissions</InputLabel>
            <Table className="min-w-full divide-y divide-gray-200 bg-white overflow-hidden rounded-lg border-2 border-gray-500">
              <TableHead>
                <TableRow>
                  <TableCell>Section</TableCell>
                  <TableCell>Permissions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.values(EPermissionSection).map((section) => (
                  <TableRow key={section}>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        width: "30%",
                        borderRight: "1px solid #e5e7eb",
                      }}
                    >
                      {formatSectionName(section)}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={2}>
                        {Object.values(EPermissionAction).map((action) => (
                          <FormControlLabel
                            key={`${section}:${action}`}
                            control={
                              <Checkbox
                                checked={permissions.includes(
                                  `${section}:${action}`
                                )}
                                onChange={(e) => {
                                  if (
                                    permissions.includes(`${section}:${action}`)
                                  ) {
                                    if (action === EPermissionAction.GET) {
                                      setPermissions((prev) =>
                                        prev.filter(
                                          (perm) =>
                                            perm !== `${section}:${action}` &&
                                            perm !==
                                              `${section}:${EPermissionAction.EDIT}` &&
                                            perm !==
                                              `${section}:${EPermissionAction.DELETE}` &&
                                            perm !==
                                              `${section}:${EPermissionAction.CREATE}`
                                        )
                                      );
                                    } else {
                                      setPermissions((prev) =>
                                        prev.filter(
                                          (perm) =>
                                            perm !== `${section}:${action}`
                                        )
                                      );
                                    }
                                  } else {
                                    if (action === EPermissionAction.CREATE) {
                                      setPermissions((prev) => [
                                        ...prev,
                                        `${section}:${action}`,
                                        `${section}:${EPermissionAction.GET}`,
                                        `${section}:${EPermissionAction.EDIT}`,
                                        `${section}:${EPermissionAction.DELETE}`,
                                      ]);
                                    } else if (
                                      action === EPermissionAction.DELETE ||
                                      action === EPermissionAction.EDIT
                                    ) {
                                      setPermissions((prev) => [
                                        ...prev,
                                        `${section}:${action}`,
                                        `${section}:${EPermissionAction.GET}`,
                                      ]);
                                    } else {
                                      setPermissions((prev) => [
                                        ...prev,
                                        `${section}:${action}`,
                                      ]);
                                    }
                                  }
                                }}
                              />
                            }
                            label={
                              action.charAt(0).toUpperCase() + action.slice(1)
                            }
                          />
                        ))}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
          <Stack spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddRole(roleName, permissions)}
              disabled={
                !hasPermission(
                  EPermissionSection.USERS,
                  EPermissionAction.CREATE
                )
              }
            >
              {!!incomingRoleName ? "Update Role" : "Add Role"}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default AddRoleDrawer;
